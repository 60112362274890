import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Spring 2022 Learning Labs Coming`}</h1>
    <p>{`The Learning Labs Spring Series is coming! Look out for more in future updates. For now, save the date for our March through June workshops - sign ups will go out February 15th, and we'll share information about workshops here and in the upcoming newsletter!`}</p>
    <h2>{`Interested in running a workshop?`}</h2>
    <p>{`We're inviting you to share your expertise with the Ada alum community as we look for Lovelace Learning Labs speakers for the Spring 2022 series! Please see the Speaker Interest form here for more information, and to `}<a parentName="p" {...{
        "href": "https://forms.gle/4UwHDe4BWRHzACrTA"
      }}>{`apply`}</a>{`. For further questions, please contact Chris at `}<a parentName="p" {...{
        "href": "mailto:chris@adadev.org"
      }}>{`chris@adadev.org`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      